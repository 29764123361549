import React, { Component } from 'react';
import Imgloading from './itemloading';
import Swal from 'sweetalert2'

class Two extends Component {

    data = this.props.Data;
    language = this.props.Language;

    showImage = (img) => {
        Swal.fire({
            imageUrl: img,
            imageAlt: 'image',
            backdrop:false,
          })
    }

    priceText(text){
        if(text == 'small')
            
            return (
                <span> 
                    {
                        this.props.Language == "tr"? 'small'
                        : this.props.Language == "krd"? 'بچیک'
                        : 'صغیر'
                    } 
                </span>
            )
        else if(text == 'medium')
            return (
                <span>
                    {
                        this.props.Language == "tr"? 'medium'
                        : this.props.Language == "krd" ? 'ناڤنجی'
                        : 'متوسط'
                    }
                </span>
            )
        else
            return <span>
                {
                    this.props.Language == "tr" ? 'large'
                    : this.props.Language == "krd" ? 'مەزن'
                    : 'کبير'
                }
            </span>
    }

    render() {
        return ( 
            <div className="item"> 
                <div className="image" onClick={ () => this.showImage(this.data.image) }>
                    <Imgloading Img={this.data.image} />
                    {/* <span className="item-order"> {this.data.order??0} </span>  */}
                </div>
                <div className="info">
                    <h5>
                        {
                            this.props.Language == "tr" ? this.data.name_tr
                            : this.props.Language == "krd" ? this.data.name_krd
                            : this.data.name_ar
                        } 
                    </h5>
                    <div className='price'> 
                    {
                        (this.data.price_small && this.data.price_max)? 
                            <>
                                
                                <span> 
                                    { this.priceText('small') } 
                                    {this.data.price_small} 
                                </span>
                                <span> 
                                    { this.priceText('medium') } 
                                    {this.data.price} 
                                </span> 
                                <span> 
                                    { this.priceText('big') }
                                    {this.data.price_max} 
                                </span> 
                            </>
                        :
                        (this.data.price_small)?
                        <>
                            
                            <span> 
                                { this.priceText('small') }
                                {this.data.price_small} 
                            </span>

                            <span> 
                                { this.priceText('medium') } 
                                {this.data.price} 
                            </span> 

                        </>
                        :
                        (this.data.price_max)?
                        <>
                            <span> 
                                { this.priceText('medium') }  
                                {this.data.price} 
                            </span> 
                            <span> 
                                { this.priceText('big') }
                                {this.data.price_max} 
                            </span> 
                        </>
                        :
                        <span> {this.data.price}  </span> 

                    }
                    </div>
                </div>
            </div>
        );
    }
}

export default Two;